export const emojis = {
    ":)": "😀",
    ":-)": "😀",
    ":D": "😁",
    ":-D": "😁",
    ":p": "😋",
    ":-p": "😋",
    ":|": "😌",
    "*.*": "😍",
    "O:)": "😇",
    "O:-)": "😇",
    "^_^": "😊",
    ":grinning:": " 😀",
    ":grinning_with_big_eyes:": "😃",
    ":grinning_with_smiling_eyes:": "😄",
    ":beaming_with_smiling_eyes:": "😁",
    ":grinning_squinting:": " 😆",
    ":grinning_with_sweat:": "😅",
    ":rolling_on_the_floor_laughing:": "🤣",
    ":tears_of_joy:": "😂",
    ":slightly_smiling:": "🙂",
    ":upside-down:": "🙃",
    ":winking:": "😉",
    ":smiling_with_smiling_eyes:": "😊",
    ":smiling_with_halo:": "😇",
    ":smiling_with_hearts:": "🥰",
    ":smiling_with_heart-eyes:": "😍",
    ":star-struck:": "🤩",
    ":blowing_a_kiss:": "😘",
    ":kissing:": "😗",
    ":kissing_with_closed_eyes:": "😚",
    ":kissing_with_smiling_eyes:": "😙",
    ":savoring_food:": "😋",
    ":with_tongue:": "😛",
    ":winking_with_tongue:": "😜",
    ":zany:": "🤪",
    ":squinting_with_tongue:": "😝",
    ":money-mouth:": "🤑",
    ":hugging:": "🤗",
    ":hand_over_mouth:": "🤭",
    ":shushing:": "🤫",
    ":thinking:": "🤔",
    ":zipper-mouth:": "🤐",
    ":raised_eyebrow:": "🤨",
    ":neutral:": "😐",
    ":expressionless:": "😑",
    ":face_without_mouth:": "😶",
    ":smirking:": "😏",
    ":unamused:": "😒",
    ":rolling_eyes:": "🙄",
    ":grimacing:": "😬",
    ":lying:": "🤥",
    ":relieved:": "😌",
    ":pensive:": "😔",
    ":sleepy:": "😪",
    ":drooling:": "🤤",
    ":sleeping:": "😴",
    ":medical_mask:": "😷",
    ":thermometer:": "🤒",
    ":head-bandage:": "🤕",
    ":nauseated:": "🤢",
    ":vomiting:": "🤮",
    ":sneezing:": "🤧",
    ":hot:": "🥵",
    ":cold:": "🥶",
    ":woozy:": "🥴",
    ":dizzy:": "😵",
    ":exploding_head:": "🤯",
    ":cowboy_hat:": "🤠",
    ":partying:": "🥳",
    ":smiling_with_sunglasses:": "😎",
    ":nerd:": "🤓",
    ":monocle:": "🧐",
    ":confused:": "😕",
    ":worried:": "😟",
    ":slightly_frowning:": "🙁",
    ":open_mouth:": "😮",
    ":hushed:": "😯",
    ":astonished": "😲",
    ":flushed:": "😳",
    ":pleading:": "🥺",
    ":frowning_with_open_mouth:": "😦",
    ":anguished:": "😧",
    ":fearful:": "😨",
    ":anxious_with_sweat:": "😰",
    ":sad_but_relieved:": "😥",
    ":crying:": "😢",
    ":loudly_crying:": "😭",
    ":screaming_in_fear:": "😱",
    ":confounded:": "😖",
    ":persevering:": "😣",
    ":disappointed:": "😞",
    ":downcast_with_sweat:": "😓",
    ":weary:": "😩",
    ":tired:": "😫"
};
