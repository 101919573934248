<template>
    <transition name="slide-fade">
        <div v-if="this.opened==true" v-bind:class="mode('chatbot-satisfaction')">
            <fieldset title="êtes vous satisfait de la réponse ?">
                <div id="chatbot-close-satisfaction-button-container">
                    <button id="chatbot-close-satisfaction-button" aria-label="Fermer la fenêtre de satisfaction" @click="closePopup()"></button>
                </div>
                <h1 v-if="this.satisfactionstep <= 2" class="merci-title">Merci d'avoir utilisé notre chatbot !</h1>
                <h1 v-else class="merci-title">Nous avons bien enregistré votre réponse.</h1>

                <p v-if="this.satisfactionstep == 1" class="merci-sub">Nous vous invitons à évaluer votre expérience</p>
                <p v-else-if="this.satisfactionstep == 2" class="merci-sub">Partagez votre avis sur votre expérience</p>
                <p v-else class="merci-sub">Merci pour votre retour ! Si vous n'avez toujours pas trouvé la réponse à votre question, vous pouvez <a href="https://www.enedis.fr/aide-contact">contacter nos services.</a></p>

                <div v-if="this.satisfactionstep == 1" id="chatbot-satisfaction-buttons-container">
                    <span v-for="(star, index) in this.grades" :key="index">
                        <button :id="'etoile' + index" v-bind:class="'score-button'" v-bind:label="getSatisfactionTxtPourMalvoyants(index)" :title="getSatisfactionTxtPourMalvoyants(index)" :aria-label="getSatisfactionTxtPourMalvoyants(index)" @click="setScore(index)">{{index+1}}</button>
                    </span>
                </div>
            
                <textarea v-if="this.satisfactionstep == 2" ref="userreview" id="user-comment-satisfaction" placeholder="Inscrivez votre réponse ici"></textarea>

                <div v-if="this.satisfactionstep <= 1" class="decevant-satisfaisant-container">
                    <span>1 - Décevante</span><span>5 - Satisfaisante</span>
                </div>
                <transition name="slide-fade">
                    <div id="satisfaction-button-wrapper" v-if="this.scored > 0">
                        <button v-if="this.satisfactionstep == 1" v-bind:class="'chatbot-satisfaction-button choice-color'" @click="btnEnvoyerEtLaisserUnAvis()">Envoyer et laisser un avis</button>
                        <button v-else-if="this.satisfactionstep == 2" v-bind:class="'chatbot-satisfaction-button choice-color'" @click="btnConfirmerMaReponse()">Confirmer ma réponse</button>

                        <button v-if="this.satisfactionstep <= 2" v-bind:class="'chatbot-satisfaction-button'" @click='btnEnvoyerSansLaisserUnAvis()'>Envoyer sans laisser d'avis</button>
                        <button v-else v-bind:class="'chatbot-satisfaction-button choice-color'" @click="closePopup()">Retour au Chatbot</button>
                    </div>
                </transition>
            </fieldset>
        </div>
    </transition>
</template>
 
<script>
    import {accessibilityService} from '../services/AccessibilityService';
    
    export default {
        name: "ChatBotSatisfaction",
        components: {},

        data () {
            return {
                scored: 0,
                opened: false,
                userId: sessionStorage.getItem("userId"),
                grades: ["1", "2", "3", "4", "5"],
                satisfactionstep: 1 // 1 on demande si on veut laisser un avis  // 2 champ input pour laisser un avis // 3 fenetre de remerciement
            };
        },

        mounted: function () {
        },
        methods: {
            mode: function (value) {
                return value + (accessibilityService.getTheme() === 'default' ? '' : '_accessibility')
            },
            cleanupBeforeLeave () {
                this.scored = 0
                this.satisfactionstep = 1
                this.opened = false
            },
            openPopup: function(buttons) {
                this.opened = true
                for (let i = 0; i < buttons.length; i++) {
                    this.grades[i] = buttons[i].title
                }
            },
            setScore: function(score) {
                this.scored = score+1
                let idname="#etoile"+score
                let scores = this.grades

                for (let i = 0; i < scores.length; i++) {
                    document.querySelector("#etoile"+i).classList.remove("score-button-selected");
                }

                document.querySelector(idname).classList.add("score-button-selected");
            },
            sendSatisfactionScore: async function(reviewMessage) {
                this.$emit("sendSatisfactionScore", this.grades[this.scored-1], reviewMessage);
            },
            btnEnvoyerEtLaisserUnAvis() {
                this.satisfactionstep = 2
            },
            btnEnvoyerSansLaisserUnAvis () {
                this.sendSatisfactionScore("")
                this.satisfactionstep = 3
            },
            btnConfirmerMaReponse() {
                let reviewmessage = this.$refs.userreview.value;
                this.satisfactionstep = 3
                this.sendSatisfactionScore(reviewmessage)
            },
            closePopup: async function() {
                this.$emit("closeSatisfactionPopup");
                this.cleanupBeforeLeave()
            },
            getSatisfactionTxtPourMalvoyants(index) {
                switch (index) {
                    case 0: return "pas satisfait"; break;
                    case 1: return "peu satisfait"; break;
                    case 2: return "moyennement satisfait"; break;
                    case 3: return "satisfait"; break;
                    case 4: return "très satisfait"; break;
                }
            }
        }
    };
</script>


<style lang="scss" scoped>
    .chatbot-satisfaction {
        display: block;
        align-items: center;
        width: 100%;
        border-top: 1px solid #DDDDDD;
        border-radius: 20px;
        margin:auto;
        background-color: $basic-color-fond;
    }

    .slide-fade-enter-active {
        transition: all .5s ease;
    }
    .slide-fade-leave-active {
        transition: all .2s ease;
    }

    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translateY(1em);
        opacity: 0;
    }

    .chatbot-satisfaction-question {
        font-size: $font-size_14PX;
        @media only screen and (max-device-width: $max_device_width_phone) {
            font-size: $font-size-min-IOS;
        }
        margin-top: 1em;
    }

    .chatbot-satisfaction-star {
        font-size: xx-large;
        margin-left: 0.2em;
        margin-right: 0.2em;
        border: none;
        background-color: inherit;
    }

    #satisfaction-button-wrapper {
        margin-top:20px
    }
    .chatbot-satisfaction-button {
        font-weight: normal;
        font-size: $font-size_13PX;
        @media only screen and (max-device-width: $max_device_width_phone) {
            font-size: $font-size-min-IOS;
        }
        border-style: solid;
        border-width: 1px;
        padding: 10px 15px;
        margin-right: auto;
        margin-left:auto;
        margin-bottom:7px;
        margin-top:9px;
        width: 100%;
        color: $basic-color;
        background-color: $basic-color-fond;
        border-radius: 20px;
        text-align:center;
        cursor:pointer;
        border: 2px solid $basic-color;

        &:hover {
            background-color: $basic-color-over;
        }
    }
    .choice-color {
        background-color: $basic-color;
        color: $basic-color-fond;
        &:hover {
            background-color: $basic-color-over2;
            border-color: $basic-color-over2;
        }
    }
    #chatbot-close-satisfaction-button-container {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
    }
    #chatbot-close-satisfaction-button {
        width: 20px;
        height: 20px;
        cursor:pointer;
        border-style: none;
        border-radius: 100%;
        background: -webkit-linear-gradient(45deg, transparent 47%, $basic-color 47%, $basic-color 53%, transparent 53%),
                    -webkit-linear-gradient(-45deg, transparent 47%, $basic-color 47%, $basic-color 53%, transparent 53%);
    }
    #chatbot-satisfaction-buttons-container {
        display: flex;
        justify-content: space-around;
    }
    .score-button {
        border-radius: 100px;
        border-color: #A1A7F1;
        padding: 12px 17px;
        border-width:1px;
        width:43px;
        height:43px;
        background-color:$basic-color-fond;
        border-style: solid;
        color: $basic-color;
    }
    .score-button:hover {
        background-color:$basic-color-over2;
        border-color: $basic-color-over2;
        cursor:pointer;
        color:$basic-color-fond;
    }
    .score-button-selected {
        background-color:$basic-color-over2;
        border-color: $basic-color-over2;
        color:$basic-color-fond;
    }

    .decevant-satisfaisant-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $basic-color;
        margin-top: 18px;
        margin-bottom: 18px;
    }

    .merci-title {
        font-size: 20px;
        text-align:center;
        color: $basic-color;
        margin-bottom:0px;
    }
    .merci-sub {
        font-size: $font-size_15PX;
        @media only screen and (max-device-width: $max_device_width_phone) {
            font-size: $font-size-min-IOS;
        }
        margin-top:15px;
    }

    fieldset {
        border-width:0px;
    }

    #user-comment-satisfaction {
        width: -webkit-fill-available; //width:100% will not work on IOS phone
        margin-top:32px;
        height:86px;
        font-size: $font-size_14PX;
        @media only screen and (max-device-width: $max_device_width_phone) {
            font-size: $font-size-min-IOS;
        }
        border: 1px $borders solid;
    }

    .chatbot-satisfaction a {
        color: $basic-color;
        text-decoration: underline;
    }
    
</style>
