import Vue from 'vue';
import Cookies from 'js-cookie'

export const accessibilityService = new Vue({
    data: {
        activeTheme: 'default'
    },
    methods: {
        getTheme () {
            const mode = Cookies.get('accessibility');
            return mode === 'true'
                ? 'accessibility'
                : 'default'
        }
    }
});
